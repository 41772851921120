import {useState} from 'react';

export function Test1() {
    const download = (filename, text) => {
        const element = document.createElement("a");
        const file = new Blob([text], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element);
        element.click();
    };

    const submitText = () => {
        const text = document.getElementById("text-val").value;
        download("text.txt", text);
    };

    return <>
        <h2>Test 1</h2>
        <textarea id="text-val" rows="4" cols="50" />
        <input type="button" value="Submit" onClick={submitText} />
    </>
} 

export function Test2() {
    const [text, setText] = useState("");

    const changeText = (event) => {
        setText(event.target.value)
    }

    return <>
        <h2>Test1</h2>
        <textarea row="4" cols="50" onChange={changeText}/>
        <p>{text}</p>
    </>;
} 