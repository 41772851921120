import React from "react";
import {Test1, Test2} from "./Test";

import config from "./config.js"

export default function App() {
  console.log(config.TEST_CST);

  return <>
    <h1>Tests</h1>
    <Test1 />
    <Test2 />
  </>;
};
